import React, { useState } from 'react'
import AdminHeader from './AdminHeader'
import axios from 'axios';

function CreateBlog() {

    const [data, setdata] = useState({
      subject:"",
      detail:"",
      image:"",
      date:"",
     
   
    });
const [respo, setrespo] = useState(null);

    const handleBlog=(e)=>{
e.preventDefault()
const formdata = new FormData()
formdata.append('subject', data.subject);
formdata.append('detail', data.detail);
formdata.append('image', data.image);
formdata.append('date', data.date);

axios.post('https://backend.menberconstructions.com.et/createBlog',formdata)
.then(res=>{
  if(res.data.status==="success"){
setrespo(res.data.status)
setdata({subject:"",detail:"",image:"" ,date:""})
  }
})
.catch(err=>console.log(err))

    }
  return (
    <div>
  <AdminHeader/>
    <div className=' d-flex justify-content-center align-items-center  '>
    <div className=' rounded bg-white p-3  naol'>
      <h2 className=' d-flex justify-content-center'>Create Blog</h2>
      { respo && <div className="alert alert-success" role="alert">
                                    <strong>You have succesfully created Blog</strong>
                                </div>}
      <form onSubmit={handleBlog} >

      <div className="form-group mb-2">
      <label htmlFor="image"> Image of Blog</label>
      <input type="file" className="form-control border"   onChange={e => setdata({ ...data, image: e.target.files[0] })} required />
    </div>
        <div className="form-group">
          <label > Subject of the Blog</label>
          <input type="text" className="form-control border" value={data.subject} onChange={e => setdata({ ...data, subject: e.target.value })} placeholder="Enter Subject of Blog" required />
        </div>
        <div className="form-group">
          <label >Content of the Blog</label>
          <textarea rows={'2'}  className="form-control border" value={data.detail} onChange={e => setdata({ ...data, detail: e.target.value })} placeholder="Enter the content of Blog" required />
        </div>
      
      
   

   





<div className="form-group mb-2">
          <label htmlFor="image">Date </label>
          <input type="date" className="form-control  border"   onChange={e => setdata({ ...data, date: e.target.value })} required />
        </div>

        <button type="submit" className="btn w-100 btn-primary">Create</button>
      </form>

    </div>
  </div>
    
    </div>
  )
}

export default CreateBlog