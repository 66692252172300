
import './App.css';

import { Route, Routes } from 'react-router-dom';

import Dashboard from './Components/Admin/Dashbord';
import Numbers from './Components/Admin/Number';
import AdminBlog from './Components/Admin/AdminBlog';
import CreateBlog from './Components/Admin/CreateBlog';
import UpdateBlog from './Components/Admin/UpdateBlog';
import Viewmessage from './Components/Admin/Viewmessage';
import TeamManagement from './Components/Admin/Team_management';
import Login from './Components/Admin/Login';
import Adminportfolio from './Components/Admin/Adminportfolio';
import { useEffect, useState } from 'react';
import { auth } from './firebase_con';
import Testimonial from './Components/Admin/Testimonial';
import Vacancycreate from './Components/Admin/Vacancycreate';
import ApplicantList from './Components/Admin/ApplicantList';
import Letter from './Components/Admin/Letter';

function App() {

  const [user, setuser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((authUser)=>{
      if(authUser){
       
      setuser(authUser)

      }})
    }, []);
   console.log(user);

  return (
   <Routes>

   {user && <Route path='/dashboard' element={<div><Dashboard/></div>}/>}
   {user && <Route path='/adminblog' element={<div><AdminBlog/></div>}/>}
   {user && <Route path='/vacancy' element={<div><Vacancycreate/></div>}/>}
   {user && <Route path='/controll' element={<div><Numbers/></div>}/>}
   {user && <Route path='/createblog' element={<div><CreateBlog/></div>}/>}
   {user && <Route path='/updateblog/:id' element={<div><UpdateBlog/></div>}/>}
   {user && <Route path='/viewmessage' element={<div><Viewmessage/></div>}/>}
   {user && <Route path='/teamcontrol' element={<div><TeamManagement/></div>}/>}
   {user &&  <Route path='/testimonialcontrol' element={<div><Testimonial/></div>}/>}
   {user && <Route path='/vacancydetail/:id' element={<div><ApplicantList/></div>}/>}
   {user && <Route path='/vacancyletter/:id' element={<div><Letter/></div>}/>}
   {user &&  <Route path='/adminportfolio' element={<div><Adminportfolio/></div>}/>}
    <Route path='/' element={<div><Login/></div>}/>

   
   </Routes>
  );
}

export default App;
