import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function TeamDelete() {
    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://backend.menberconstructions.com.et/teamfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);


    const deleteTea=(id)=>{

     

        const conf=window.confirm(` Are you sure  you Want to Delete this Team member? `)
        if(conf){
            axios.delete("https://backend.menberconstructions.com.et/deleteteam/"+id)
            .then(res=>{
              if (res.data.status==="success") {
                alert("you have seccessfully deleted the Team!")
                window.location.reload()
                
              }
            })
            .catch(err=>console.log(err))
        }
    
    }


  return (
    

    <div>
    {data.length>0 &&   <section id="team" class="team">
  
      <div class="container" data-aos="fade-up">
  
        <header class="section-header">
          <h2>Team</h2>
          <p className='d-flex justify-content-center '>Our hard working team</p>
        </header>
  
        <div class="row gy-4">
  
         
          {data.map((teams,i)=>{
            return  <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100" key={i}>
            
            <div class="member" >
              <div class="member-img">
                <img src={'https://backend.menberconstructions.com.et/images/' + teams.Image} class="img-fluid" alt=""/>
                <div class="social bg-white">
                 
                 <Link onClick={()=>deleteTea(teams.id)} className="social-tw" ><i className="bi bi-trash3"></i></Link>
                </div>
              </div>
              <div class="member-info">
                <h4>{teams.Name}</h4>
                <span>{teams.Position}</span>
               
              </div>
            </div>
            </div>
          }) }
  
        
  
  
        </div>
  
      </div>
  
    </section>}
      
      
      </div>



 
    
   
  )
}

export default TeamDelete