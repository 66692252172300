import React, { useEffect, useState } from 'react'
import co from '../../images/about.jpg'
import coo from '../../images/si.jpeg'
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Letter() {
    const{id}=useParams()
const [data, setdata] = useState([]);
const [vacancy, setvacancy] = useState([]);
    useEffect(() => {
        axios.get('https://backend.menberconstructions.com.et/letter/'+id)
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result[0])
       
         
        axios.get('https://backend.menberconstructions.com.et/vacancydetail/' + res.data.Result[0].vacancy_id)
          .then(res => {
            if (res.data.status === "success") {
                
              setvacancy(res.data.Result[0])
             
            }
          })
          .catch(err => console.log(err))
     
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);

    console.log(data);
  

  return (
    <div className='d-flex justify-content-center px-3 align-items-center py-5'>
    <div className='rounded  p-3 px-md-5  border'>
    <div  className='d-flex justify-content-center'>
    <img src={co} alt='' className='lklk'/>
    
    </div>
    <div  className='d-flex  justify-content-center mb-3'>
  
    <h3 className='texi-light'>Applicant Letter</h3>
    </div>
<ul>
<li><b>name:</b> {data.name}</li>
<li><b>email:</b> {data.email}</li>

</ul>

<div  className='d-flex  justify-content-center mt-2'>
  
<h5 className='texi-light text-center'>Applying for {vacancy.subject} position</h5>
</div>
<div  className='d-flex  justify-content-center mt-2'>
  
<p className='texi-light text-justify'>{data.message}</p>
</div>
<ul>
<li  className=' mb-4'><b>Sincerely!</b> </li>
<li><b>name:</b> {data.name}</li>
<li>

<img src={coo} alt='' className='lklku'/>

</li>

</ul>


    </div>
    </div>
  )
}

export default Letter