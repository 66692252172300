import React, { useState } from 'react'
import AdminHeader from './AdminHeader'
import axios from 'axios';

import TestimonialDelete from './TestimonialDelete';

function Testimonial() {

    const [data, setdata] = useState({
        position:"",
        name:"",
        image:"",
        message:"",
      });
      
  const [respo, setrespo] = useState(null);
  
      const testimon=(e)=>{
  e.preventDefault()
  
  const formdata = new FormData()
  formdata.append('position', data.position);
  formdata.append('name', data.name);
  formdata.append('message', data.message);
  formdata.append('image', data.image);
 axios.post('https://backend.menberconstructions.com.et/testimon',formdata)
  .then(res=>{
    if(res.data.status==="success"){
  setrespo(res.data.status)
  
  window.location.reload();
  setdata({position:"",name:"",image:"",message:""
  })

    }
  })
  .catch(err=>console.log(err))
  
      }

  return (
    <div>
    <AdminHeader/>
 
    <div className=' d-flex justify-content-center align-items-center  '>
    <div className=' rounded bg-white p-3  naol'>
      <h2 className=' d-flex justify-content-center'>Create Testimonial</h2>
      { respo && <div className="alert alert-success" role="alert">
                                    <strong>You have succesfully created one Testimonial!!</strong>
                                </div>}
      <form onSubmit={testimon} >
 <div className="form-group mb-2 ">
      <label htmlFor="image">Image </label>
      <input type="file" className="form-control  border-dark"   onChange={e => setdata({ ...data, image: e.target.files[0] })} required />
    </div>

        <div className="form-group ">
          <label >Name</label>
          <input type="text" className="form-control  border-dark" value={data.name} onChange={e => setdata({ ...data, name: e.target.value })} placeholder="Enter Name" required />
        </div>

       
        
        <div className="form-group ">
          <label >Jop position</label>
          <input type="text" className="form-control  border-dark" value={data.position} onChange={e => setdata({ ...data, position: e.target.value })} placeholder="Enter jop Position " required />
        </div>
        <div className="form-group ">
        <label >Message</label>
        <textarea className="form-control  border-dark" value={data.message} onChange={e => setdata({ ...data, message: e.target.value })} placeholder="Enter message " required ></textarea>
      </div>
     <button type="submit" className="btn w-100 btn-primary">Create</button>
      </form>

    </div>
  </div>

  <TestimonialDelete/>
    
    </div>
  )
}

export default Testimonial