import axios from 'axios';
import { signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../firebase_con';
import co from '../../images/about.jpg'
function AdminHeader() {
const [data, setdata] = useState([]);
useEffect(() => {
    axios.get("https://backend.menberconstructions.com.et/newmessage")
    .then(res=>{
if (res.data.status==="success") {
    setdata(res.data.Result)
}
    })
    .catch(err=>console.log(err))
    
}, []);
const navigate=useNavigate()
const handlelogout=()=>{
    signOut(auth)
    .then(res=>navigate('/'))
    .catch(err=>console.log(err))
}

  return (
    <div>
  
    


<div className="nav-bar1" style={{backgroundColor:' #FFFFFF'}}>
<div className="container-fluid p-0">
    <nav className="navbar navbar-expand-lg bg-dark navbar-dark ">
        <Link to={'/dashboard'} className="navbar-brand"><img style={{width:'50px'}} className="rounded-circle " src={co} alt=""/></Link>

<div>
        
        <button type="button" className="navbar-toggler  " data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon rounded bg-dark"></span>
        </button>
</div>
        <div className="collapse navbar-collapse justify-content-between bg-white " id="navbarCollapse">
            <div className="navbar-nav mr-auto">
                <Link to={'/dashboard'} className="nav-item nav-link active text-dark ">Dashboard</Link>
                <Link to={'/vacancy'} className="nav-item nav-link active text-dark ">Create Vacancy</Link>
                <Link to={'/controll'} className="nav-item nav-link active text-dark ">Number Controller</Link>
               
               
                <div className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle text-dark" data-toggle="dropdown">Management</Link>
                    <div className="dropdown-menu ">
                        
                        <Link to={'/createblog'} className="dropdown-item" >Create Blog </Link>
                        <Link to={'/adminblog'} className="dropdown-item">Delete/Update Blog  </Link>
                        <Link to={'/teamcontrol'} className="dropdown-item" >Manage Team </Link>
                        <Link to={'/adminportfolio'} className="dropdown-item" >Manage Projects </Link>
                        <Link to={'/testimonialcontrol'} className="dropdown-item" >Manage Testimonies </Link>
                    </div>
                </div>
                
                <Link onClick={handlelogout} className="nav-item nav-link text-dark">Log out</Link>
            </div>
            <div className="mr-4">
            <Link to={'/viewmessage'} className="h2 text-white text-decoration-none" ><i className="bi bi-chat-dots text-dark "></i><sup className="text-danger  "> {data.length}</sup></Link>
            </div>
        </div>
    </nav>
</div>
</div>
    </div>
  )
}

export default AdminHeader