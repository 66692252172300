import axios from 'axios';
import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom';
function PortfolioDelete() {
    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://backend.menberconstructions.com.et/portfoliofetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);


    const deleteportfolio=(id)=>{

     

        const conf=window.confirm(` Are you sure  you Want to Delete this Project? `)
        if(conf){
            axios.delete("https://backend.menberconstructions.com.et/deleteportfolio/"+id)
            .then(res=>{
              if (res.data.status==="success") {
                alert("you have seccessfully deleted the Portfolio")
                window.location.reload()
                
              }
            })
            .catch(err=>console.log(err))
        }
    
    }

  return (
    <div>
    
    <section id="portfolio" class="portfolio">

    <div class="container" data-aos="fade-up">
    
      <header class="section-header">
        <h2>Portfolio</h2>
      
      </header>
    

    
      <div class="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
    
    
       {data.map((porti,i)=>{
        return  <div class="col-lg-4 col-md-6 portfolio-item filter-web" key={i}>
        <div class="portfolio-wrap">
        <img src={'https://backend.menberconstructions.com.et/images/' + porti.image} class="img-fluid" alt=""/>
          <div class="portfolio-info">
            <h4>{porti.name}</h4>
            <p>{porti.type}</p>
            <div class="portfolio-links">
            
              <Link onClick={()=>deleteportfolio(porti.id)} title="More Details"><i className="bi bi-trash3"></i></Link>
            </div>
          </div>
        </div>
      </div>
       })}
    
       
    
      
    
      
    
       
    

    
       
    
       
    
      </div>
    
    </div>
    
    </section>
    
    </div>
  )
}

export default PortfolioDelete