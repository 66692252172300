import React, { useEffect, useState } from 'react'
import AdminHeader from './AdminHeader'

import {  createUserWithEmailAndPassword, signOut} from 'firebase/auth'

import { auth } from '../../firebase_con'
import { useNavigate, useParams } from 'react-router-dom'
import Vacancy from './Vacancy'
import axios from 'axios'



function Dashbord() {
  const navigate=useNavigate()
  const [data, setdata] = useState({

    email:'',
    password:''
  });
  const [err, seterr] = useState(null);
  const [user, setuser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((authUser)=>{
      if(authUser){
       
      setuser(authUser)

      }})
    }, []);
    const handlelogout=()=>{
      signOut(auth)
      .then(res=>navigate('/'))
      .catch(err=>console.log(err))
  }
  const register=(e)=>{
    e.preventDefault()
 createUserWithEmailAndPassword(auth,data.email,data.password)
    .then(alert("You have succefully created user!!"))
    .catch(err=>{
      seterr(err.message)
    })
  }
 
 
  return (
    <div className="wrapper">
    <AdminHeader/>




   <div className='row mx-3 my-2 '>
   
   <div className='col-md-6 col-lg-8'>
   <div className='rounded-2 mb-5   text-dark   '>
   <h1 className='text-dark'>Welcome</h1>
   {user && <h4 className='text-primary'>{user.email}</h4>}
   
   <button onClick={handlelogout} type="button" className="btn btn-danger">Log out</button>
   </div>
   
   
   </div>

   <div className='col-md-6 col-lg-4'>
   
   
   <div className="card-body p-lg-5  shadow-5 text-center mb-5">

   
     <h2 className="fw-bold ">Register new User</h2>
     <form onSubmit={register}  className="pt-3">
    {err && <div className="alert alert-primary" role="alert">
      <strong>{err}</strong>
     </div>}
        <div className="form-group my-3">
          <input type="email" className="form-control "  onChange={e => setdata({ ...data, email: e.target.value })}
            placeholder="Enter Email" required/>
        </div>
        <div className="form-group">
          <input type="password" className="form-control "  onChange={e => setdata({ ...data, password: e.target.value })}
            placeholder="Enter Password" required/>
        </div>
        <div className="mt-3">
          <button type='submit' className="btn btn-block w-100 btn-primary btn-lg font-weight-medium auth-form-btn"
           >SIGN UP</button>
        </div>

      </form>
   </div>
   
   </div>
   
   </div>
<Vacancy/>

    </div>
  )
}

export default Dashbord