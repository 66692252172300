import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import AdminHeader from './AdminHeader';

function Number() {
  
    const [data, setdata] = useState({
        happy:'',
        projects:'',
        workers:''
    
      });
const [respo, setrespo] = useState(null);
useEffect(() => {
    axios.get("https://backend.menberconstructions.com.et/numberfetch")
    .then(res=>{
if (res.data.status==="success") {
    setdata({
      happy:res.data.Result[0].happy,
      projects:res.data.Result[0].projects,
      workers:res.data.Result[0].workers

    })
  
}
        
    })
    .catch(err=>console.log(err))
   
}, []);




    const handleupdate=(e)=>{
e.preventDefault()



axios.put('https://backend.menberconstructions.com.et/numberupdate',{happy:data.happy,projects:data.projects,workers:data.workers})
.then(res=>{
  if(res.data.status==="success"){
setrespo(res.data.status)

  }
})
.catch(err=>console.log(err))

    }

    
	
	
    
    
    

  return (
    <div>
    <AdminHeader/>
    
  
    <div className='d-flex justify-content-center px-3 align-items-center py-5'>
    <div className='rounded  p-3 px-md-5  border'>
   
    <div  className='d-flex  justify-content-center mb-3 text-center'>
    <h2 className=' '>Update Numbers of Projects,Happy Clients And Workers</h2>
   
   
    </div>
    { respo && <div className="alert alert-success" role="alert">
        <strong>You have succesfully Updated the Numbers</strong>
    </div>}
    
    <form onSubmit={handleupdate} >
      <div className="form-group">
        <label >Happy Clients</label>
        <input type="number" className="form-control border-2 border" value={data.happy} onChange={e => setdata({ ...data, happy: e.target.value })} placeholder="Enter Number of happy clients" required />
      </div>
    
      <div className="form-group">
      <label >Projects</label>
      <input type="number" className="form-control border-2 border" value={data.projects} onChange={e => setdata({ ...data, projects: e.target.value })} placeholder="Enter Number of completed projects" required />
    </div>
    <div className="form-group">
        <label >Workers</label>
        <input type="number" className="form-control border-2 border" value={data.workers} onChange={e => setdata({ ...data, workers: e.target.value })} placeholder="Enter Number of Workers" required />
      </div>
     
      <button type="submit" className="btn w-100 btn-primary">Update</button>
    </form>


    </div>
    </div>
    </div>
  )
}

export default Number