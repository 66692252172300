import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
const firebaseConfig = {

  apiKey: "AIzaSyC2W2k0oeO8msG3Zuh13-jUHgruik3qw1Y",

  authDomain: "menberconstructions.firebaseapp.com",

  projectId: "menberconstructions",

  storageBucket: "menberconstructions.appspot.com",

  messagingSenderId: "816170399089",

  appId: "1:816170399089:web:161341262f8adf499ef2d0",

  measurementId: "G-6GJ8JWCZN5"

};



  const app = initializeApp(firebaseConfig);
export const auth=getAuth(app)