import React, { useState } from 'react'
import AdminHeader from './AdminHeader'
import axios from 'axios';
import TeamDelete from './TeamDelete';

function Team_management() {

    const [data, setdata] = useState({
        position:"",
        name:"",
        image:"",
        facebook:"",
        instagram:"",
        tweeter:"",
        linkedin:"",
        message:""
      });
  const [respo, setrespo] = useState(null);
  
      const team=(e)=>{
  e.preventDefault()
  const formdata = new FormData()
  formdata.append('position', data.position);
  formdata.append('name', data.name);
  formdata.append('facebook', data.facebook);
  formdata.append('instagram', data.instagram);
  formdata.append('tweeter', data.tweeter);
  formdata.append('linkedin', data.linkedin);
  formdata.append('message', data.message);
  formdata.append('image', data.image);
  console.log(formdata);
  axios.post('https://backend.menberconstructions.com.et/createteam',formdata)
  .then(res=>{
    if(res.data.status==="success"){
  setrespo(res.data.status)
  
  window.location.reload();
  setdata({position:"",name:"",image:"",facebook:"",instagram:"",
    tweeter:"",
    linkedin:"",
     message:""
  })

    }
  })
  .catch(err=>console.log(err))
  
      }

  return (
    <div>
    <AdminHeader/>
 
    <div className=' d-flex justify-content-center align-items-center  '>
    <div className=' rounded bg-white p-3  naol'>
      <h2 className=' d-flex justify-content-center'>Create Team</h2>
      { respo && <div className="alert alert-success" role="alert">
                                    <strong>You have succesfully created Team Member!!</strong>
                                </div>}
      <form onSubmit={team} >

      <div className='row'>
      <div className="form-group mb-2 col-6">
      <label htmlFor="image">Image of Team</label>
      <input type="file" className="form-control  border-dark"   onChange={e => setdata({ ...data, image: e.target.files[0] })} required />
    </div>

        <div className="form-group col-6">
          <label >Name</label>
          <input type="text" className="form-control  border-dark" value={data.name} onChange={e => setdata({ ...data, name: e.target.value })} placeholder="Enter Name" required />
        </div>

        </div>
        <div className='row'>
        <div className="form-group col-6">
          <label >Jop position</label>
          <input type="text" className="form-control  border-dark" value={data.position} onChange={e => setdata({ ...data, position: e.target.value })} placeholder="Enter jop Position " required />
        </div>
        <div className="form-group col-6">
        <label >Facebook Link</label>
        <input type="text" className="form-control  border-dark" value={data.facebook} onChange={e => setdata({ ...data, facebook: e.target.value })} placeholder="Enter Facebook Link "  />
      </div>
      </div>
<div className='row'>
      <div className="form-group col-6">
      <label >instagram Link</label>
      <input type="text" className="form-control  border-dark" value={data.instagram} onChange={e => setdata({ ...data, instagram: e.target.value })} placeholder="Enter Instagram Link "  />
    </div>
    <div className="form-group col-6">
    <label >twitter Link</label>
    <input type="text" className="form-control  border-dark" value={data.tweeter} onChange={e => setdata({ ...data, tweeter: e.target.value })} placeholder="Enter Twetter Link"  />
  </div>
  </div>
  <div className="form-group mb-3">
  <label >Linkedin Link</label>
  <input type="text" className="form-control  border-dark" value={data.linkedin} onChange={e => setdata({ ...data, linkedin: e.target.value })} placeholder="Enter Linkedin Link "  />
</div>

<div className="form-group ">
<label >Message</label>
<textarea className="form-control  border-dark" value={data.message} onChange={e => setdata({ ...data, message: e.target.value })} placeholder="Enter message " required ></textarea>
</div>  
        <button type="submit" className="btn w-100 btn-primary">Create</button>
      </form>

    </div>
  </div>

  <TeamDelete/>
    
    </div>
  )
}

export default Team_management