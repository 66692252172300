import React, { useEffect, useState } from 'react'
import AdminHeader from './AdminHeader'
import axios from 'axios';
import { useParams } from 'react-router-dom';

function UpdateBlog() {
  const { id } = useParams();
    const [data, setdata] = useState({
      subject:"",
      detail:"",
      image:""
    });
const [respo, setrespo] = useState(null);

    const handleupdate=(e)=>{
e.preventDefault()
const formdata = new FormData()
formdata.append('subject', data.subject);
formdata.append('detail', data.detail);
formdata.append('image', data.image);
axios.put('https://backend.menberconstructions.com.et/update/'+id,formdata)
.then(res=>{
  if(res.data.status==="success"){
setrespo(res.data.status)

  }
})
.catch(err=>console.log(err))

    }

    
	
	useEffect(() => {
	  axios.get('https://backend.menberconstructions.com.et/detail/' + id)
		.then(res => {
		  if (res.data.status === "success") {
			setdata({
        subject:res.data.Result[0].subject,
        detail:res.data.Result[0].detail
      })
		  }
		})
		.catch(err => console.log(err))
	}, []);
  return (
    <div>
    <AdminHeader/>
    <div className=' d-flex justify-content-center align-items-center  my-4'>
    <div className=' rounded bg-white p-3  naol'>
      <h2 className=' d-flex justify-content-center'>Update Blog</h2>
      { respo && <div className="alert alert-success" role="alert">
                                    <strong>You have succesfully Updated the Blog</strong>
                                </div>}
      <form onSubmit={handleupdate} >
        <div className="form-group">
          <label >Subject of the Blog</label>
          <input type="text" className="form-control border-2 border" value={data.subject} onChange={e => setdata({ ...data, subject: e.target.value })} placeholder="Enter Subject of Blog" required />
        </div>
        <div className="form-group">
          <label >Content of the Blog</label>
          <textarea rows={'4'}  className="form-control border-2 border" value={data.detail} onChange={e => setdata({ ...data, detail: e.target.value })} placeholder="Enter the content of Blog" required />
        </div>
      
        <div className="form-group mb-2">
        <label htmlFor="image">Image of Blog</label>
        <input type="file" className="form-control border-2 border"   onChange={e => setdata({ ...data, image: e.target.files[0] })} required />
      </div>
        <button type="submit" className="btn w-100 btn-primary">Update</button>
      </form>

    </div>
  </div>
    
    </div>
  )
}

export default UpdateBlog