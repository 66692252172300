import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import AdminHeader from './AdminHeader';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const ApplicantList = () => {
  const [applicant, setapplicant] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    axios.get("https://backend.menberconstructions.com.et/applicantfetch/"+id)
    .then(res=>{
if (res.data.status==="success") {
  console.log(res.data.Result);
  setapplicant(res.data.Result.sort((a, b) => b.id - a.id))
    
}
        
    })
    .catch(err=>console.log(err))
   
}, [id]);



  return (
    <div>
    <AdminHeader/>
   
    <Table striped bordered hover responsive>
    <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Email</th>
        <th>Job Title</th>
        <th>Letter</th>
        <th>Resume</th>
      </tr>
    </thead>
    <tbody>
      {applicant.map((applicant, index) => (
        <tr key={applicant.id}>
          <td>{index + 1}</td>
          <td>{applicant.name}</td>
          <td>{applicant.email}</td>
          <td>{applicant.subject}</td>
          <td>  <Link className='text-decoration-none' to={`/vacancyletter/${applicant.apo}`} target="_blank" rel="noopener noreferrer"> Letter
          </Link></td>

          <td>
            <Link className='text-decoration-none' to={'https://backend.menberconstructions.com.et/images/' + applicant.cv} target="_blank" rel="noopener noreferrer">
              View Resume
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>

    </div>
  );
};

export default ApplicantList;