import React, { useEffect, useState } from 'react'

import passport from '../../images/about.jpg'

import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';

function Vacancy() {
  const [data, setdata] = useState([]);
  useEffect(() => {
      axios.get("https://backend.menberconstructions.com.et/vacancyfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata(res.data.Result.sort((a, b) => b.id - a.id))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);



  const deleteVacancy=(id)=>{

     

    const conf=window.confirm(` Are you sure  you Want to Delete this Vacancy? `)
    if(conf){
        axios.delete("https://backend.menberconstructions.com.et/deletevacancy/"+id)
        .then(res=>{
          if (res.data.status==="success") {
            alert("you have seccessfully deleted Vacancy!")
            window.location.reload()
            
          }
        })
        .catch(err=>console.log(err))
    }

}

const navigate=useNavigate()
const nnnn=(id)=>{
    navigate(`/vacancydetail/${id}`)
}

  return (
    <div>
 <section class="site-section" id="next">
      <div className="container">
        <ul className="job-listings mb-5 border-0">
         {data.map((list,i)=>{
        return  <li className="job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center mb-5 border-dark" key={i}>
           
            <div className="job-listing-logo">
              <img src={passport} alt="" className="img-fluid"/>
            </div>

            <div className="job-listing-about d-sm-flex custom-width w-100 justify-content-between mx-4">
              <div className="job-listing-position custom-width w-50 mb-3 mb-sm-0">
                <h2>{list.subject}</h2>
                <div className="job-listing-meta">
                <span className="badge badge-danger">{list.type}</span>
              </div>
            
              </div>
              <div className="job-listing-location mb-3 mb-sm-0 custom-width w-25">
                <span className="icon-room"><i className="bi bi-geo-alt-fill"></i>{list.location}</span> 
              </div>
              <div className="job-listing-meta d-flex justify-content-center">
              <button onClick={()=>deleteVacancy(list.id)} className="btn btn-danger py-0 mr-1">Delete </button>
                <button onClick={()=>nnnn(list.id)}  className="btn btn-warning py-0">View Applicants</button>
              </div>
            </div>
            
          </li>
         }) }
   
        </ul>


      </div>
    </section>
    
    
    </div>
  )
}

export default Vacancy