import React, { useState } from 'react'
import AdminHeader from './AdminHeader'
import axios from 'axios';

function Vacancycreate() {

  const [data, setdata] = useState({
    subject:"",
    detail:"",
    image:"",
    deadline:"",
   
     location:"",
     salary:"",
     type:"",
     experience:"",
     gender:""
 
  });
const [respo, setrespo] = useState(null);

  const handlevacancy=(e)=>{
e.preventDefault()
const formdata = new FormData()
formdata.append('subject', data.subject);
formdata.append('description', data.description);
formdata.append('image', data.image);
formdata.append('deadline', data.deadline);
formdata.append('type', data.type);
formdata.append('location', data.location);
formdata.append('salary', data.salary);
formdata.append('experience', data.experience);
formdata.append('gender', data.gender);
axios.post('https://backend.menberconstructions.com.et/vacancycr',formdata)
.then(res=>{
if(res.data.status==="success"){
setrespo(res.data.status)
setdata({subject:"",description:"" ,deadline:"",salary:"",experience:"",location:"" })
}
})
.catch(err=>console.log(err))

  }



  return (
    <div>
    <AdminHeader/>

    
    <section className="site-section">
      <div className="container">

        <div className=" align-items-center mb-5 ">
          <div className=" mb-4 mb-lg-0">
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <h2>Post A Job</h2>
              </div>
            </div>
          </div>
         
        </div>
        <div className="row mb-5">
          <div className="col-lg-12">
            <form class="p-4 p-md-5 border rounded" onSubmit={handlevacancy}>
              <h3 className="text-black mb-5 border-bottom pb-2">Job Details</h3>
              { respo && <div className="alert alert-success" role="alert">
                <strong>You have succesfully created Vacancy</strong>
            </div>}
              
              <div className="form-group mb-2">
              <label htmlFor="image"> Image </label>
              <input type="file" className="form-control border"   onChange={e => setdata({ ...data, image: e.target.files[0] })} required />
            </div>

              <div className='row'>
              <div className="form-group col-6">
              <label htmlFor="job-title">Job Title</label>
              <input type="text" class="form-control bg-secondary" id="job-title" placeHolder="Product Designer" value={data.subject} onChange={e => setdata({ ...data, subject: e.target.value })} required/>
            </div>
            <div className="form-group col-6">
              <label htmlFor="job-location">Location</label>
              <input type="text" class="form-control bg-secondary" id="job-location" placeHolder="e.g. Addis Abeba" value={data.location} onChange={e => setdata({ ...data, location: e.target.value })} required/>
            </div>
              
              </div>
             
              <div className='row'>
              <div className="form-group col-6">
                <label htmlFor="gender">Gender</label>
                <select class="selectpicker border rounded w-100 p-2" id="gender" data-style="btn-black" data-width="100%" data-live-search="true" title="Select Job Type" onChange={e => setdata({ ...data, gender: e.target.value })}  required>
                  <option >Male</option>
                  <option >Female</option>
                  <option >Any</option>
                </select>
              </div>

            <div className="form-group col-6">
            <label htmlFor="experience">Experience</label>
            <input type="text" class="form-control bg-secondary" id="experience" placeHolder="Required Experience" value={data.experience} onChange={e => setdata({ ...data, experience: e.target.value })} required/>
          </div>
          </div>

          <div className='row'>
          <div className="form-group col-6">
          <label htmlFor="salary">Salary</label>
          <input type="text" class="form-control bg-secondary" id="salary" placeHolder="Salary" value={data.salary} onChange={e => setdata({ ...data, salary: e.target.value })} required/>
        </div>
 <div className="form-group col-6">
              <label htmlFor="deadline">Deadline</label>
              <input type="date" class="form-control bg-secondary" id="deadline" value={data.deadline} onChange={e => setdata({ ...data, deadline: e.target.value })} required/>
            </div>
            </div>



              <div className="form-group">
                <label htmlFor="job-type">Job Type</label>
                <select class="selectpicker border rounded w-100 p-2" id="job-type" data-style="btn-black" data-width="100%" data-live-search="true" title="Select Job Type" onChange={e => setdata({ ...data, type: e.target.value })}  required>
                  <option >Part Time</option>
                  <option >Full Time</option>
                </select>
              </div>


              <div className="form-group ">
                <label htmlFor="job-description">Job Description</label>
                <textarea class="form-control bg-secondary" id="job-description" value={data.description} onChange={e => setdata({ ...data, description: e.target.value })} placeholder='job description' required></textarea>
              </div>
           
              <div className="d-flex justify-content-center ">
               <button type="submit" class="btn btn-primary">Post Vacancy</button>
           
              </div>
             
 </form>
          </div>

         
        </div>
       
          
        
           
         
      </div>
    </section>
    
    
    
    </div>
  )
}

export default Vacancycreate